import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Navbar from "./components/layout/navbar/navbar";
import Social from "./components/social/social";
import Studio from "./pages/studio/studio";
import SupportedBy from "./pages/supported-by/supported-by";
import Workshop from "./pages/workshop/workshop";
import Contact from "./pages/contact/contact";
import Home from "./pages/home/Home";
import PlayerWrapper from "./components/playerWrapper/playerWrapper";

function App() {
  const [general, setGeneral]: [any, (general: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/general`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setGeneral(response.data);
        setLoading(false);
      });
  }, []);
  return (
   <div className="flex flex-col h-screen">
    <div className="App bg-identityGray-500 relative flex-1 ">
      {/* <Social /> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/workshops" element={<Workshop />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/supported-by" element={<SupportedBy />} />
      </Routes>
    </div>
     <PlayerWrapper />
     </div>
  );
}

export default App;
