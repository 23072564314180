import * as React from "react";
import { Link } from "react-router-dom";
import Slider from "../../components/slider/slider";
import Player from "../../components/player/player";
import Picks from "../../components/picks/picks";
import Flower from "../../components/flower/flower";
import Chat from "../../components/chat/chat";
import Schedule from "../../components/schedule/schedule";
import Events from "../../components/events/events";

function Home() {
  return (
    <>
      <main>
        <Slider />
        {/* <Picks />
        <div className="flex flex-row h-screen">
          <div className="flex-1 flex flex-col">
            <div className="w-full h-3/6 relative">
              <Flower />
            </div>
            <div className="w-full h-3/6 relative">
              <Chat />
            </div>

          </div>
          <div className="flex-1 flex flex-col">

            <div className="w-full flex-1 flex">
              <Schedule />
            </div>
          </div>
        </div>
        <Events /> */}
      </main>
    </>
  );
}

export default Home;
