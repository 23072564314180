import axios from "axios";
import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Link } from "react-router-dom";
import image1 from "../../assets/slide-1.jpg";
import image2 from "../../assets/slide-2.jpg";
import image3 from "../../assets/slide-3.jpg";
import image4 from "../../assets/slide-4.jpg";
import image5 from "../../assets/slide-5.jpg";
import image6 from "../../assets/slide-6.jpg";
import './slider.css';
function onClickItem(arg: any) { console.log(arg) };
function onClickThumb(arg: any) { console.log(arg) };
function Slider() {
  const [slider, setSlider]: [any, (slider: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/collections/get/slider`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSlider(response.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Carousel className="slider" autoPlay={true} showStatus={false} showIndicators={false} showArrows={true} showThumbs={false} onClickItem={onClickItem} onClickThumb={onClickThumb}>

        <div>
          <img src={image5} />
          <p className="legend"></p>
        </div>
        <div>
          <img src={image6} />
          <p className="legend"></p>
        </div>
        <div>
          <img src={image1} />
          <p className="legend"></p>
        </div>
        <div>
          <img src={image2} />
          <p className="legend"></p>
        </div>
        <div>
          <img src={image3} />
          <p className="legend"></p>
        </div>
        <div>
          <img src={image4} />
          <p className="legend"></p>
        </div>

      </Carousel>
    </>
  );
}

export default Slider;
