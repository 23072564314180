import axios from "axios";
import * as React from "react";
import supportedBy from "../../assets/supported-by.png";
function SupportedBy() {

  const [about, setAbout]: [any, (about: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/about`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAbout(response.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <main className="text-white mt-44 container m-auto  overflow-auto container-limited">
        <h2 className="text-3xl mb-8">Supported by</h2>
        <p>This project is supported by<br /></p>
        <div className="grid grid-cols-1 p-4 px-8 gap-8">
          <div className="w-full">
            <img src={supportedBy} className="w-full mt-5 grayscale hover:grayscale-0" />
          </div>
          
          
        </div>
      </main>
    </>
  );
}

export default SupportedBy;
