import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import back from "../../../assets/back-b.svg";
import logo from "../../../assets/logo.svg";
import menu from "../../../assets/menu-b.svg";

function Navbar() {
  const [menuOpen, setMenuOpen]: [boolean, (error: boolean) => void] = React.useState<boolean>(false);
  return (
    <>
      {menuOpen &&
        <div className="flex flex-col bg-identityGray-400 w-60 h-full absolute z-50 px-6 py-4">
         <div className="flex justify-between">
         <button className="flex w-28 justify-around py-0 px-1 items-center opacity-50 hover:opacity-100 text-white border border-white rounded-full text-sm">
          English  
          <FontAwesomeIcon icon={faChevronDown} /> 
          </button>
         <button className="w-7 opacity-50 hover:opacity-100" onClick={() => setMenuOpen(false)}>
          <img src={back} />
          </button>
         </div>
         <nav className="flex flex-col items-start px-1 mt-12">
            <Link className="my-3 text-xl text-identityGray-200 hover:text-white" onClick={() => setMenuOpen(false)} to="/">
              Home
            </Link>
            <Link className="my-3 text-xl text-identityGray-200 hover:text-white"  onClick={() => setMenuOpen(false)} to="/studio">
              Studio
            </Link>
            <Link className="my-3 text-xl text-identityGray-200 hover:text-white" onClick={() => setMenuOpen(false)} to="/workshops">
              Workshops
            </Link>
            <Link className="my-3 text-xl text-identityGray-200 hover:text-white" onClick={() => setMenuOpen(false)} to="/contact">
              Contact us
            </Link>
            <Link className="my-3 text-xl text-identityGray-200 hover:text-white" onClick={() => setMenuOpen(false)} to="/supported-by">
              Supported by
            </Link>
          </nav>
        </div>
      }
      <div className="flex flex-col fixed z-20 w-full">
        <div className="flex items-center justify-between  p-6">
          <div className="flex flex-1">
            <div className="text-center w-24 justify-start">
              <button className="overflow-hidden relative h-full w-10 opacity-60 hover:opacity-100" onClick={() => setMenuOpen(true)}>
                <img src={menu} className="w-full" />
              </button>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <div className="text-center w-24">
              <Link to="/">
                <img src={logo} className="w-full" alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="flex flex-1">
            <div className="text-center w-24">

            </div>
          </div>
          {/* 
          <nav className="flex items-center">
            <Link className="mx-4 font-bold" to="/about">
              About
            </Link>
            <Link className="mx-4 font-bold" to="/residents">
              Residents
            </Link>
            <Link className="mx-4 font-bold" to="/guests">
              Guests
            </Link>
            <Link className="mx-4 font-bold" to="/events">
              Events
            </Link>
            <Link className="mx-4 font-bold" to="/shows">
              Shows
            </Link>
            <Link className="mx-4 font-bold" to="/shows">
              Shop
            </Link>
          </nav> */}
          {/* <div className="flex">
            <input placeholder="Type to search.." className="mx-2 border rounded-2xl border-black px-3 py-1" />
            <button className="border rounded-full border-black px-2 py-1">
               <FontAwesomeIcon icon={faSearch} />
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Navbar;
