import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useEffect, useState } from "react";
import Marquee from "react-double-marquee";
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import "./player.css";
import play from "../../assets/play-b.svg";
import logo from "../../assets/logo.svg";
declare const rjq: any;
function createScript(scriptSrc: string, onload: any | null = null) {
  const script = document.createElement("script");
  script.src = scriptSrc;
  script.async = true;
  document.head.appendChild(script);
  script.onload = onload;
}
function updateInfo(data: any) { }
function scriptLoaded(stateSetter: any) {
  rjq("#rjp-radiojar-player").radiojar("player", {
    streamName: "9kw6sr0acxhvv",
    enableUpdates: true,
    autoplay: false,
  });
  rjq("#rjp-radiojar-player").off("rj-track-load-event");
  rjq("#rjp-radiojar-player").on(
    "rj-track-load-event",
    (event: any, data: any) => {
      updateInfo(data);
      console.log(data);
      stateSetter(data);
    }
  );
}
function Player() {
  const [data, setData] = useState({ artist: "", title: "", thumb: "" });
  console.log("re-render");
  useEffect(() => {
    createScript(
      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    );
    createScript(
      "https://www.radiojar.com/wrappers/api-plugins/v1/radiojar-min.js",
      () => scriptLoaded(setData)
    );
  }, []);
  return (
    <>
      <div id="rj-player" className="w-full text-white">
        <div id="rjp-radiojar-player"></div>
        <div className="player-medium flex justify-between items-center p-4 h-full">
          <div className="flex">
            <div>
              <div id="rj-cover" className="w-24 h-24  rounded-lg  border border-white overflow-hidden flex items-center">
                {data.thumb && <img className="w-full" src={data.thumb} alt="" title=""></img>}
                {!data.thumb && <div className="bg-identityGray-300 w-full h-full p-4 flex items-center"><img src={logo} alt="" title=""></img></div>}
              </div>
            </div>
            <div className="info p-1 px-4">
              <div className="rjp-trackinfo-container text-left flex">
                <div className="flex-1 overflow-hidden whitespace-nowrap">
                  <div className="flex items-center text-white text-sm font-bold leading-none	">
                    <span className="mr-1 text-2xl line-height-0 animate-ping text-red-500 px-0.5">•</span>LIVE
                  </div>
                  <p className="text-2xl mt-0">
                  <Marquee direction="left" scrollWhen="overflow" speed={0.03}>
                    {data.title}
                  </Marquee>
                  </p>
                  <p id="trackInfo" className="rjp-info truncate text-sm">
                    {data.artist}
                  </p>
                </div>
              </div>
              <div className="rjp-player-container hidden">
                <div id="rjp-radiojar-player"></div>
                <div id="rj-player-controls" className="rj-player-controls">
                  <div className="jp-gui jp-interface hidden">
                    <div className="jp-controls">
                      <div className="jp-volume-bar-wrapper">
                        <div className="jp-volume-bar box-content">
                          <div className="jp-volume-bar-value"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jp-no-solution">
                  <span>Update Required</span>
                  To play the media you will need to either update your browser to
                  a recent version or update your{" "}
                  <a href="http://get.adobe.com/flashplayer/" target="_blank">
                    Flash plugin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <a href="javascript:;" className="jp-play w-20 opacity-40 hover:opacity-100" title="Play">
              <img src={play} className="w-full" />
            </a>
            <a href="javascript:;" className="jp-pause w-20 opacity-40 hover:opacity-100" title="Pause" style={{ display: 'none' }}>
              <img src={play} className="w-full" />
            </a>
          </div>
        </div>
      </div>
      {/* <div id="rj-player">
        <div className="player-v3 player-medium">
          <div className="info">
            <div className="rjp-trackinfo-container text-left flex">
              <div className="flex justify-center items-start pr-3 py-1">
                <div className="rounded border border-red-500 px-3 pb-0.5 text-red-500 text-md font-bold leading-none	">
                  <span className="mr-1  animate-ping">•</span>Live
                </div>
              </div>
              <div className="flex-1 overflow-hidden whitespace-nowrap">
                <Marquee direction="left" scrollWhen="overflow" speed={0.03}>
                  {data.title}
                </Marquee>
                <p id="trackInfo" className="rjp-info truncate text-xs">
                  {data.artist}
                </p>
              </div>
            </div>
            <div className="rjp-player-container">
              <div id="rjp-radiojar-player"></div>
              <div id="rj-player-controls" className="rj-player-controls">
                <div className="jp-gui jp-interface">
                  <div className="jp-controls">
                    <a href="javascript:;" className="jp-play" title="Play">
                    <FontAwesomeIcon icon={faPlay} />
                    </a>
                    <a href="javascript:;" className="jp-pause" title="Pause" style={{display:'none'}}>
                    <FontAwesomeIcon icon={faPause} />
                    </a>
                    <div className="jp-volume-bar-wrapper">
                      <div className="jp-volume-bar box-content">
                        <div className="jp-volume-bar-value"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jp-no-solution">
                <span>Update Required</span>
                To play the media you will need to either update your browser to
                a recent version or update your{" "}
                <a href="http://get.adobe.com/flashplayer/" target="_blank">
                  Flash plugin
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Player;
