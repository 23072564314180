import axios from "axios";
import * as React from "react";
import Slider from "../../components/slider/slider";
import studio1 from "../../assets/studio-1.jpg";
import studio2 from "../../assets/studio-2.jpg";
import studio3 from "../../assets/studio-3.jpg";
import studio4 from "../../assets/studio-4.jpg";
function Contact() {

  const [about, setAbout]: [any, (about: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/about`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAbout(response.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <main className="text-white mt-44 container m-auto  overflow-auto container-limited">
        <h2 className="text-3xl mb-8">Contact us</h2>
        <p>
        <b>info@anderer-kunstverein.eu</b>  <br/><br/>
        Anderer Kunstverein e.V. - Elisabethstraße 17 - 04315 Leipzig <br/>
        registernummer: VR 6611 Amtsgericht Leipzig <br/>
        Vorstand: Saeed Albatal, Ferdinand Klüsener, Michael Mander
        </p>
          
      </main>
    </>
  );
}

export default Contact;
