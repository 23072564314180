import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import Player from "../player/player";
function PlayerWrapper() {
  return (
    <>
      <div className="flex justify-around bg-identityGray-500  w-full h-32 bottom-0 flex-shrink-0 border-t-2 border-identityGray-400">
        
          <Player />

          </div>
    </>
  );
}

export default PlayerWrapper;
