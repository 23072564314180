import axios from "axios";
import * as React from "react";
import Slider from "../../components/slider/slider";
import workshop1 from "../../assets/workshop1.jpg";
import workshop2 from "../../assets/workshop2.jpg";
import workshop3 from "../../assets/workshop3.jpg";
import workshop4 from "../../assets/workshop4.jpg";
import workshop5 from "../../assets/workshop5.jpg";
import workshop6 from "../../assets/slide-5.jpg";
function Workshop() {

  const [about, setAbout]: [any, (about: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/about`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAbout(response.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <main className="text-white mt-44 container m-auto  overflow-auto container-limited">
        <h2 className="text-3xl mb-8">Workshops</h2>
        <p>AActivities in the public space connect the studio and the surrounding area. <br/>
        In this way, the studio succeeds in establishing itself more strongly in the neighborhood as a socio-cultural center.</p>
        <div className="w-full p-8">
            <img src={workshop5} className="w-full  " />
          </div>
        <div className="grid  grid-flow-col p-4 px-8 gap-8 grid-rows-9">

          <div className="w-full row-span-1">
            <img src={workshop2} className="w-full" />
          </div>
          <div className="w-full row-span-1">
            <img src={workshop3} className="w-full" />
          </div>
          
          <div className="w-full row-span-3">
            <img src={workshop1} className="w-full  " />
          </div>
          <div className="w-full row-span-1">
            <img src={workshop4} className="w-full" />
          </div>
         
          <div className="w-full row-span-1">
            <img src={workshop6} className="w-full" />
          </div>
        </div>
      </main>
    </>
  );
}

export default Workshop;
